import moment from "moment";
let Highcharts = require("highcharts/highstock");
require("highcharts/modules/annotations")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

let valueFormatter = Intl.NumberFormat("nl-NL", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
let axisValueFormatter = Intl.NumberFormat("nl-NL", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
let percentageFormatter = Intl.NumberFormat("nl-NL", {
  style: "percent",
  minimumFractionDigits: 2,
});

moment.locale("nl-NL");

class MeewindChart {
  constructor(elementId, data) {
    /** Set withdrawData */
    this.data = data;
    this.elementId = elementId;
    let withdrawData = [];
    let annotation = null;
    let startingRange = {
      min:
        data.fundHistory.length >= 14
          ? data.fundHistory[data.fundHistory.length - 13].x
          : data.fundHistory[0].x,
      max: data.fundHistory[data.fundHistory.length - 1].x,
    };
    if (Array.isArray(data.mutationsMinus)) {
      data.mutationsMinus.forEach(function (v) {
        let found = data.fundHistory.find(function (element) {
          let days = 16;
          let margin = days * 24 * 60 * 60 * 1000;
          let top = v[0] + margin;
          let bot = v[0] - margin;
          return element.x > bot && element.x < top;
        });
        if (found != undefined) {
          withdrawData.push({
            type: "image",
            src: "/images/hc_alert_icon.svg",
            width: 20,
            height: 22,
            point: {
              xAxis: 0,
              yAxis: 0,
              x: found.x,
              y: found.y,
            },
            x: -6,
            y: -20,
          });
        }
      });
      annotation = {
        annotations: [
          {
            shapes: withdrawData,
          },
        ],
      };
    }

    const currentYear = new Date().getFullYear();

    this.chartOptions = {
      navigator: { enabled: false },
      chart: {
        renderTo: elementId,
        spacingTop: 10,
        spacingBottom: 0,
        height: 400,
        plotBorderWidth: 0,
      },
      title: {
        text: "",
      },
      plotOptions: {
        series: {
          showInLegend: false,
          lineWidth: 1,
        },
      },
      xAxis: {
        // type: "category",
        type: "datetime",
        startOnTick: true,
        endOnTick: true,
        // min: startingRange.min,
        // max: startingRange.max,
        min: Date.UTC(currentYear, 0, 1),
        max: Date.UTC(currentYear, 11, 31),
        plotLines: data.mutationsPlus
          .filter((m) => Array.isArray(m) && m.length >= 1)
          .map((m) => ({
            color: "#e5f9f9",
            width: 25,
            value: m[0], // x timestamp
            dashStyle: "Solid",
            zIndex: 3,
          })),
        events: {
          setExtremes: function (e) {
            if (e.trigger == "rangeSelectorButton") {
              // Always set extremes based on the year button pressed
              setTimeout(function () {
                e.target.chart.xAxis[0].setExtremes(
                  Date.UTC(Number(e.rangeSelectorButton.text), 0, 1),
                  Date.UTC(Number(e.rangeSelectorButton.text), 11, 31),
                );
              }, 1);
            }
          },
        },
        labels: {
          useHtml: true,
          formatter: function () {
            return (
              "<b>" +
              Highcharts.dateFormat("%b '%y", this.value).replace(".", "") +
              "</b>"
            );
          },
          x: 28,
          y: 30,
        },
        crosshair: {
          className: "xCrosshair",
          zIndex: 0,
        },
        gridLineWidth: 1,
        tickInterval: 31 * 24 * 3600 * 1000,
        tickWidth: 1,
        tickLength: 20,
        lineColor: "#e6e6e6",
        tickColor: "#e6e6e6",
        showLastLabel: false,
      },
      yAxis: [
        {
          allowDecimals: true,
          labels: {
            useHtml: true,
            formatter: function () {
              return "€ <b>" + axisValueFormatter.format(this.value) + "</b>";
            },
            align: "right",
            style: {
              fontSize: "12px",
            },
          },
          title: {
            text: "",
          },
          resize: {
            enabled: true,
          },
          maxPadding: 0.2,
        },
        {
          tickPositions: [],
          title: { text: "" },
          labels: { enabled: false },
          visible: true,
          min: 0,
          opposite: true,
          gridLineWidth: 0,
        },
      ],

      series: [
        {
          type: "line",
          name: "Waarde",
          data: data.fundHistory,
          color: data.color,
          lineWidth: 4,
          marker: {
            enabled: false,
          },
          className: "currencySeries",
        },
        {
          type: "column",
          name: "Stortingen",
          yAxis: 1,
          data: data.mutationsPlus,
          className: "currencySeries",
        },
        {
          type: "column",
          name: "Onttrekkingen",
          yAxis: 1,
          data: data.mutationsMinus,
          className: "currencySeries",
        },

        {
          type: "scatter",
          name: "Dividenduitkering",
          yAxis: 0, // use primary Y axis for proper scaling
          data: data.mutationsPlus.map((p) => ({
            x: p[0],
            y: p[1],
            dataLabels: {
              enabled: true,
              useHTML: true,
              formatter: function () {
                return `
                  <span style="
                    font-size: 11px;
                    font-weight: bold;
                    color: #000;
                    white-space: nowrap;
                  ">Dividenduitkering</span>
                `;
              },
              align: "center",
              y: -180,
              crop: false,
              overflow: "none",
            },
          })),
          // marker: {
          //   enabled: true,
          //   radius: 5,
          //   symbol: "circle",
          //   fillColor: "#EF9008",
          // },
          showInLegend: false,
          enableMouseTracking: false,
          color: "transparent",
          states: {
            inactive: {
              enabled: false,
            },
            hover: {
              enabled: false,
            },
          },
        },
      ],

      ...annotation,
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        useHTML: true,
        borderRadius: 15,
        hideDelay: 2000,
        zIndex: 999999,
        shape: "rect",
        formatter: function () {
          return this.points.reduce(
            function (s, point) {
              let extra =
                typeof point.point.extra !== "undefined" &&
                point.point.extra != ""
                  ? "<div class='point-extra' style='font-size:11px;'>(" +
                    point.point.extra +
                    ")</div>"
                  : "";
              return (
                s +
                '<div class="tooltip-text">' +
                '<div class="seriesValueBlock seriesValueBlock' +
                point.series.name +
                '">' +
                point.series.name +
                ':&nbsp;<span class="seriesValue series' +
                point.series.name +
                '">' +
                (point.series.options.className == "currencySeries"
                  ? valueFormatter.format(point.y) + " EUR"
                  : percentageFormatter.format(point.y / 100)) +
                "</span></div>" +
                extra +
                "</div>"
              );
            },
            '<div class="tooltip-date" style=""><b>' +
              Highcharts.dateFormat("%d-%m-%Y", this.x) +
              "</b></div>",
          );
        },
        borderWidth: 0,
        shadow: false,
      },
      lang: {
        shortMonths: moment.monthsShort(),
        rangeSelectorZoom: "Bekijk periode",
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 600,
            },
            chartOptions: {
              yAxis: [
                {
                  labels: {
                    enabled: false,
                  },
                },
                {},
              ],
            },
          },
        ],
      },
    };

    this.chart = Highcharts.chart(this.chartOptions);
    this.generateButtons();
  }

  generateButtons() {
    let buttons = [];
    // setting up range selection
    // console.dir(this.data);
    let firstYear = moment(this.data.fundHistory[0].x).year();
    let lastYear = moment(
      this.data.fundHistory[this.data.fundHistory.length - 1].x,
    ).year();

    // buttons.push({
    //   year: "Alle",
    //   min: Date.UTC(firstYear, 1, 1),
    //   max: Date.UTC(lastYear, 12, 31),
    // });

    for (let i = firstYear; i <= lastYear; i++) {
      buttons.push({
        year: i,
        min: Date.UTC(i, 0, 1),
        max: Date.UTC(i, 11, 31),
      });
    }
    let bc = document.getElementById("buttonContainer");
    if (bc) {
      bc.remove();
    }

    let buttonContainer = document.createElement("div");
    let graphContainer = document.getElementById(this.elementId);

    buttonContainer.id = "buttonContainer";
    buttonContainer.className =
      "d-flex justify-content-start align-items-center mt-4";
    graphContainer.after(buttonContainer);

    let fo = document.createElement("div");
    fo.className = "me-2 fw-bold d-none d-lg-block";
    fo.textContent = "Bekijk periode";
    buttonContainer.append(fo);

    let dd = document.createElement("div");
    dd.className = "dropdown";
    buttonContainer.append(dd);

    let dt = document.createElement("div");
    dt.className = "me-2 d-block d-lg-none btn dropdown-toggle";
    dt.textContent = "Selecteer periode  ";
    dt.setAttribute("data-bs-toggle", "dropdown");
    dt.setAttribute("aria-expanded", "false");
    dt.setAttribute("type", "button");
    dd.append(dt);

    let bw = document.createElement("ul");
    bw.id = "buttonWrapper";
    bw.className = "dropdown-menu";
    dd.append(bw);

    let classthis = this;

    [].forEach.call(buttons, function (el) {
      let b = document.createElement("button");
      let bwwrap = document.createElement("li");
      bwwrap.className = "dropdown-item";
      bw.appendChild(bwwrap);
      b.classList.add("badge");
      b.classList.add("me-2");
      b.textContent = el.year;
      bwwrap.appendChild(b);

      b.addEventListener("click", function (e) {
        classthis.chart.xAxis[0].setExtremes(el.min, el.max);
        let activeButtons = document.querySelectorAll(
          "#buttonContainer button.active",
        );

        [].forEach.call(activeButtons, function (el) {
          el.classList.remove("active");
        });

        e.target.classList.add("active");
      });
    });
    return buttons;
  }

  getChart() {
    return this.chart; // Return the chart object
  }
}

window.MeewindChart = MeewindChart;
