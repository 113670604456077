export class Taxforeigncheck {
    constructor() {
        
        var timeout = null;
        var _this = this;

        $('.crsdownload').on('change', function(){
            var link = $(this).val();
            window.open(link, '_blank');
        });        
    }
}

window.Taxforeigncheck = Taxforeigncheck;