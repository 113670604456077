export class AddressCheck {
    constructor(addressCheckRoute) {
        
        var timeout = null;
        var _this = this;
        var addressCheckRoute = addressCheckRoute;

        $('.jsHousenumber').keyup(function() {
            clearTimeout(timeout);
            timeout = setTimeout(_this.postcodeCheck($(this), addressCheckRoute), 500);
        });

        jQuery('.jsCountrySelector').on('change', function() {
            _this.adaptAddressFieldsToCountry($(this));
        });

        jQuery('.numberaddition-manual').on('click', function (event) {
            const parent = $(this).closest('.panel');

            event.preventDefault();

            jQuery('.numberaddition-nl', parent).hide();
            jQuery('.numberaddition-other-country', parent).show();

            const numberAdditionNL = jQuery('.numberaddition-nl select', parent);
            numberAdditionNL.attr('name', numberAdditionNL.attr('data-linkedwith'));

            jQuery('.numberaddition-other-country input', parent)
                .attr('disabled', false)
                .attr('name', numberAdditionNL.attr('data-original'))
                .tooltip({
                    title: 'Vul hier uw tussenvoegsel in',
                    trigger: 'manual'
                }).tooltip('show');
        });

        jQuery('.numberaddition-other-country input').on('focus', function () {
          // Check and ensure that the tooltip is initialized before calling dispose
          if (jQuery(this).data('bs.tooltip')) { 
            jQuery(this).tooltip('dispose');
          }
        });
        
        $('.jsCountrySelector').each(function (index, element) {
            _this.adaptAddressFieldsToCountry(this);
        });
    }

    adaptAddressFieldsToCountry(element) {
        var isNL = jQuery(element).val() == 'NL';

        jQuery(element)
            .closest('.panel')
            .find('.postal-code-nl')
            .toggle(isNL);

        jQuery(element)
            .closest('.panel')
            .find('.postal-code-other-country')
            .toggle(! isNL);

        jQuery(element)
            .closest('.panel')
            .find('.numberaddition-other-country')
            .toggle(! isNL);

        var postalcodeField = jQuery(element)
            .closest('.panel')
            .find('.postal-code-nl')
            .not('.numberaddition-nl')
            .find('input');

        postalcodeField
            .attr('name', isNL ? postalcodeField.attr('data-original') : postalcodeField.attr('data-linkedwith'));
            
        jQuery(element)
            .closest('.panel')
            .find('.postal-code-other-country')
            .find('input')
            .attr('name', ! isNL ? postalcodeField.attr('data-original') : postalcodeField.attr('data-linkedwith'));

        var numberaddition_field = jQuery(element)
            .closest('.panel')
            .find('.postal-code-nl.numberaddition-nl')
            .find('select');

        numberaddition_field
            .attr('name', isNL ? numberaddition_field.attr('data-original') : numberaddition_field.attr('data-linkedwith'));
        
        jQuery(element)
            .closest('.panel')
            .find('.numberaddition-other-country')
            .find('input')
            .attr('name', ! isNL ? numberaddition_field.attr('data-original') : numberaddition_field.attr('data-linkedwith'));

          var tooltipField = jQuery(element)
          .closest('.panel')
          .find('.numberaddition-other-country input');
            // Check and ensure that the tooltip is initialized before calling dispose
          if (tooltipField.data('bs.tooltip')) {
            tooltipField.tooltip('dispose');
          }
    }

    postcodeCheck(element, addressCheckRoute) {
        var postcode = jQuery(element).closest('.panel').find('.jsPostalcode').val();
        var huisnummer = jQuery(element).val();
        var toevoegsel = jQuery(element).closest('.panel').find('.jsAddition').val();
        var straat = jQuery(element).closest('.panel').find('.jsStreet');
        var woonplaats = jQuery(element).closest('.panel').find('.jsCity');
        var country = jQuery(element).closest('.panel').find('.jsCountrySelector option:selected').val();

        if (country != 'NL' || postcode == '' || huisnummer == '' ) {
            return false;
        } 
        if(postcode.length != 6 && huisnummer.length == 0 ){
            return false;
        }
        if(!toevoegsel){
            toevoegsel = '';
        }
        var r = jQuery.ajax({
            type: 'GET',
            url: addressCheckRoute,
            data: { 
                postalcode : ""+postcode,
                housenumber : parseInt(huisnummer),
                toevoegsel : ""+toevoegsel,
            },
            timeout: 6000,
            contentType: "application/x-www-form-urlencoded",
            async: true,
            beforeSend: function() {
                var loadingIcon = '<div class="loadingIcon lds-ring"><div></div><div></div><div></div><div></div></div>';
                var $select = $('.jsAddition'); 
                $select.after(loadingIcon);
            },
            error: function() {
                jQuery('.numberaddition-manual').trigger('click');
            },
            success: function(data) {
                straat.val(data.street).valid();
                woonplaats.val(data.city).valid();
                var listitems;
                var $select = $(element).closest('.panel').find('.jsAddition'); 
                if (data.houseNumberAdditions === undefined || data.houseNumberAdditions.length == 1) {
                    $select.find('option').remove();
                    $select.attr('disabled', 'disabled');
                } else {
                    $select.closest('.form-group').removeClass('has-success');
                    $select.find('option').remove(); 
                    $.each(data.houseNumberAdditions, function(key, value){
                        listitems += '<option value="' + value + '">' + value + '</option>';
                    });
                    $select.append(listitems);
                    $select.removeAttr('disabled');
                }
                jQuery('.loadingIcon').remove();
            }
        });
    }
}

window.AddressCheck = AddressCheck;