export class PasswordIndicator {
    constructor() {
        
        var timeout = null;
        var _this = this;

        $('#password').on('keyup', function() {
            var value = $(this).val();
            
            var capitalMatch = new RegExp('[A-Z]+');
            if(capitalMatch.test(value)) {
                $('#capital').addClass('ok');
            } else {
                $('#capital').removeClass('ok');
            }

            var lowercaseMatch = new RegExp('[a-z]+');
            if(lowercaseMatch.test(value)) {
                $('#lowercase').addClass('ok');
            } else {
                $('#lowercase').removeClass('ok');
            }

            var specialMatch = new RegExp(/\W/);
            if(specialMatch.test(value)) {
                $('#special').addClass('ok');
            } else {
                $('#special').removeClass('ok');
            }

            var numberMatch = new RegExp('[0-9]+');
            if(numberMatch.test(value)) {
                $('#number').addClass('ok');
            } else {
                $('#number').removeClass('ok');
            }

            var minimumMatch = new RegExp('^.{8,}$');
            if(minimumMatch.test(value)) {
                $('#minimum').addClass('ok');
            } else {
                $('#minimum').removeClass('ok');
            }
        });
    }
}

window.PasswordIndicator = PasswordIndicator;