import intlTelInput from 'intl-tel-input';

export class Phone {
    constructor() {
        const inputs = document.querySelectorAll(".jsPhone");

        inputs.forEach(function(input) {
            var placeholderNumberType = 'MOBILE';
            if (input.classList.contains('placeholderNumberTypeFixedLine')) {
                placeholderNumberType = 'FIXED_LINE';
            }

            var iti = intlTelInput(input, {
                utilsScript: "utils.js",
                preferredCountries: ['nl', 'be'],
                separateDialCode: true,
                hiddenInput: "full_" + input.name,
                placeholderNumberType: placeholderNumberType
            });

            var countryDropdown = document.querySelector(".jsCountrySelector");

            if (countryDropdown !== null) {
                countryDropdown.addEventListener('change', function() {
                    iti.setCountry(this.value);
                });
            }
        });
    }
}

window.Phone = Phone;
