/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import $ from "jquery";

window.$ = window.jQuery = $;

import Chart from "chart.js";

window.Chart = Chart;

import Pace from "pace-js/pace.js";

// require('bootstrap/dist/js/bootstrap.bundle.min.js');
require("bootstrap/dist/js/bootstrap.min.js");
require("bootstrap-pincode-input");
require("select2/dist/js/select2.min.js");
require("select2/dist/js/i18n/nl.js");

import "jquery-ui/ui/widgets/autocomplete.js";
import "jquery-ui/ui/widgets/slider.js";
import "jquery-ui/ui/widgets/tooltip.js";

import flatpickr from "flatpickr";
import { nl } from "flatpickr/dist/l10n/nl.js";
import Inputmask from "inputmask";
import DataTable from "datatables.net/js/jquery.dataTables.js";

function requireAll(r) {
	r.keys().forEach(r);
}
/** Use this to require from subfolder to keep it clear */
requireAll(require.context("./onboarding/", true, /\.js$/));
requireAll(require.context("./modules/", true, /\.js$/));

Number.prototype.formatMoney = function(c, d, t) {
	var n = this,
		c = isNaN((c = Math.abs(c))) ? 2 : c,
		d = d == undefined ? "." : d,
		t = t == undefined ? "," : t,
		s = n < 0 ? "-" : "",
		i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
		j = (j = i.length) > 3 ? j % 3 : 0;
	return (
		s +
		(j ? i.substr(0, j) + t : "") +
		i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
		(c
			? d +
			  Math.abs(n - i)
					.toFixed(c)
					.slice(2)
			: "")
	);
};

jQuery(document).ready(function($) {
	$('[data-bs-toggle="tooltip"]').tooltip();
	$("#flash-overlay-modal").modal();

	flatpickr(".datepicker", {
		enableTime: true,
		time_24hr: true,
		enableSeconds: true,
		locale: "nl",
	});

	flatpickr(".calendar", {
		enableTime: false,
		time_24hr: true,
		enableSeconds: false,
		dateFormat: "d-m-Y",
		locale: "nl",
		altInputClass: "dob",
		allowInput: true,
		wrap: true,
		onOpen: function(selectedDates, dateStr, instance) {
			if ($(".calendar .flatpickr-input").attr("data-past") == "no") {
				this.set("minDate", new Date());
			}
			if ($(".calendar .flatpickr-input").attr("data-future") != "yes") {
				this.set("maxDate", new Date());
			}
			this.setDate(dateStr); // this sets the date if you enter manually
		},
	});

	$(".printTable").click(function() {
		window.print();
		return false;
	});

	Inputmask().mask(document.querySelectorAll("input"));

	$(".table.clickrows tr").on("click", function() {
		var element = $(this);
		if (!element.hasClass("clicked")) {
			var url = element.attr("data-linkrow");
			var target = element.attr("data-linktarget");
			if (url) {
				element.addClass("clicked");
				// $('html').addClass('wait');
				if (target != "_blank") {
					window.location.href = url;
				}
				if (target == "_blank") {
					window.open(url, "_blank");
				}
			}
		}
	});

	if (window.location.hash) {
		var elem = $("#" + window.location.hash.replace("#", ""));
		if (elem) {
			setTimeout(function() {
				$("body, html").animate(
					{
						scrollTop: $(elem).offset().top - 100,
					},
					500
				);
			}, 200);
		}
	}

	$("select#month_year").on("change", function() {
		this.form.submit();
	});

	$('.logout').on('click', function() {
		window.stop();
	});
});
$.extend(true, DataTable.defaults, {
	language: {
		// language settings
		lengthMenu: "<span class='seperator'>|</span> Bekijk _MENU_ rijen ",
		info: "<span class='seperator'>|</span> _TOTAL_ rijen gevonden ",
		processing:
			"<div class='spinner'> <div class='rect1'></div> <div class='rect2'></div> <div class='rect3'></div> <div class='rect4'></div> <div class='rect5'></div> </div>",
		infoEmpty: "",
		infoFiltered: " - gefilterd in _MAX_ rijen",
		emptyTable: "Geen gegevens gevonden",
		zeroRecords: "Geen data gevonden",
		search: "<i class='fa fa-search'></i>",
		paginate: {
			previous: "Vorige",
			next: "Volgende",
			last: "Laaste",
			first: "Eerste",
			page: "Pagina",
			pageOf: "van",
		},
	},
});
$(document).ready(() => {
	$(".confirmation-needed").click((e) => {
		const msg = $(e.currentTarget).data("message");
		return confirm(msg);
	});

	// turn on bootstrap popover
	$('[data-bs-toggle="popover"]').popover();

	if ($(".validateandproceed").length > 0) {
		$(".validateandproceed").on("click", function(e) {
			var parentCollapse = $(this)
				.closest(".panel")
				.find(".collapse[data-parent]");
			var next = $(this).attr("data-nextpanel");

			if (next === undefined) {
				$(parentCollapse).collapse("hide");
				// parentCollapseHeader.addClass('done');
			} else {
				$(next).collapse("show");
			}
			e.preventDefault();
			return false;
		});
	}
});

// Page menu nav animation (used in Funds page)
document.addEventListener("DOMContentLoaded", function () {
  function isDesktop() {
    return window.innerWidth >= 992;
  }

  if (isDesktop()) {
    const menuContainer = document.querySelector(".page-nav-container");
    const menuItems = document.querySelectorAll(
      ".page-nav-container .page-nav-item"
    );
    const underline = document.querySelector(
      ".page-nav-container .page-nav-underline"
    );
    let lastHoveredItem = null;

    if (menuContainer && menuItems && underline) {
      menuItems.forEach(function (item) {
        item.addEventListener("mouseover", function () {
          const itemRect = item.getBoundingClientRect();
          const containerRect = menuContainer.getBoundingClientRect();

          const leftPosition = itemRect.left - containerRect.left;
          const width = itemRect.width;

          underline.style.width = width + "px";
          underline.style.left = leftPosition + "px";
          lastHoveredItem = item;
        });
      });

      menuContainer.addEventListener("mouseleave", function (event) {
        if (!lastHoveredItem) return;

        const itemRect = lastHoveredItem.getBoundingClientRect();
        const containerRect = menuContainer.getBoundingClientRect();
        const leftPosition = itemRect.left - containerRect.left;
        const rightPosition = containerRect.right - itemRect.right;

        if (event.clientX < containerRect.left) {
          // Mouse left from the left side of the container
          underline.style.left = leftPosition + "px";
        } else if (event.clientX > containerRect.right) {
          // Mouse left from the right side of the container
          underline.style.left = leftPosition + itemRect.width + "px";
        } else if (
          event.clientY < containerRect.top ||
          event.clientY > containerRect.bottom
        ) {
          // Mouse left from the top or bottom of the container
          if (
            itemRect.left + itemRect.width / 2 <
            containerRect.left + containerRect.width / 2
          ) {
            // Item is on the left side of the container
            underline.style.left = leftPosition + "px";
          } else {
            // Item is on the right side of the container
            underline.style.left = leftPosition + itemRect.width + "px";
          }
        }

        underline.style.width = "0";
      });
    }
  }
});