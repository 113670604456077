export class AccountActivation {
    constructor() {
        this.setStatusToDone();
    }

    setStatusToDone() {
        jQuery('.iconWrapper .icon')
        .addClass('done');
        jQuery('.hourglass-step')
        .addClass('is-complete');
        jQuery('.iconLabel')
        .html('<input type="submit" name="start" value="Start met beleggen" class="btn btn-primary startflow mt-2 mx-auto">');
        jQuery('.startflow')
        .attr('disabled', false);
        // jQuery('.warningWrapper').fadeOut();
        jQuery('.activateHeader').text(jQuery('.activateHeader').attr('data-alternative'));
    }
}

window.AccountActivation = AccountActivation;