export class PhoneCountryCodeIntegration {
    constructor(company = false) {
        let phonePrimary = company ? $('#field_companyData[phone_primary]') : $('#field_phone_primary');
        if (phonePrimary.length) {
            let hiddenPhonePrimaryElement = company ? $('form input[name="companyData[full_companyData[phone_primary]]"]') : $('form input[name="full_phone_primary"]');
            let phonePrimaryCountryCodeSelector = company ? '#collapse_companyData[phone_primary] .iti__selected-flag .iti__flag' : '#collapse_phone_primary .iti__selected-flag .iti__flag';
            let phonePrimaryCountryCode = $(phonePrimaryCountryCodeSelector);

            // After every phone number change it updates hidden full phone number field
            // to have correct phone number validation and to have correct phone number by submitting of the form.
            phonePrimary.on('keyup', function() {
                updateFullPhone(hiddenPhonePrimaryElement, phonePrimary, getCountryCode(phonePrimaryCountryCode));
            });

            // If country code will be changed it clears phone number part.
            addPhoneCountryCodeMutationObserver(phonePrimaryCountryCodeSelector, phonePrimary);
        }

        // no company registration with secondary phone
        let secondPhonePrimary = $('#field_second_phone_primary');
        if (secondPhonePrimary.length) {
            let hiddenSecondPhonePrimaryElement = $('form input[name="full_second_phone_primary"]');
            let secondPhonePrimaryCountryCodeSelector = '#collapse_second_phone_primary .iti__selected-flag .iti__flag';
            let secondPhonePrimaryCountryCode = $(secondPhonePrimaryCountryCodeSelector);

            secondPhonePrimary.on('keyup', function() {
                updateFullPhone(hiddenSecondPhonePrimaryElement, secondPhonePrimary, getCountryCode(secondPhonePrimaryCountryCode));
            });

            addPhoneCountryCodeMutationObserver(secondPhonePrimaryCountryCodeSelector, secondPhonePrimary);
        }

        /**
         * Update hidden full phone number (phone number with correct country code)
         *
         * @param hiddenPhoneElement
         * @param phone
         * @param countryCode
         */
        function updateFullPhone(hiddenPhoneElement, phone, countryCode) {
            let intlTelInputInstance = window.intlTelInputGlobals.getInstance(phone.get(0));
            intlTelInputInstance.setCountry(countryCode === undefined ? 'nl' : countryCode);
            hiddenPhoneElement.val(intlTelInputInstance._getFullNumber());
        }

        function getCountryCode(countryCodeElement) {
            return countryCodeElement.attr('class').match(/[a-z]{2}$/g) !== null
                ? countryCodeElement.attr('class').match(/[a-z]{2}$/g)[0] : 'nl';
        }

        /**
         * Mutation observer currently only clears phone number part if country code was changed
         *
         * @param phoneCountrySelector
         * @param phoneInput
         */
        function addPhoneCountryCodeMutationObserver(phoneCountrySelector, phoneInput) {
            let phoneMutationObserver = new MutationObserver(function(mutationList, observer) {
                mutationList.forEach(function(mutationRecord) {
                    if (mutationRecord.attributeName === 'class' && mutationRecord.target.className !== mutationRecord.oldValue) {
                        phoneInput.val('');
                    }
                });
            });
            phoneMutationObserver.observe(document.querySelector(phoneCountrySelector),{
                'attributes': true,
                'attributeOldValue': true,
            });
        }
    }
}

window.PhoneCountryCodeIntegration = PhoneCountryCodeIntegration;
