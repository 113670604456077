export class Minorcheck {
    constructor() {
        var minorCheckField = $('#field_minorcheck');
        $('#nextStepButton').attr('disabled', true);
        minorCheckField.on('change', function(){
            if(minorCheckField.is(":checked")) {
                $('#nextStepButton').attr('disabled', false);
            } else {
                $('#nextStepButton').attr('disabled', true);
            }
        });
    }
}

window.Minorcheck = Minorcheck;