export class IdentificationCheck {
    constructor() {
        var later = $('#idLater');
        var now = $('#idNow');
        $('.switch #field_identification_fill_in_later').on('click', function() {
            if (jQuery('#field_identification_fill_in_later').prop('checked')) {
                now.hide();
                later.show();
            } else {
                now.show();
                later.hide();
            }
        });
    }
}

window.IdentificationCheck = IdentificationCheck;